<template>
  <div>
    <div class="top-banner">
      <div class="bg"></div>
      <div class="ban">
        <div class="title-big">American Delivery Service </div>
        <div class="title-small">
          <div>Experience hassle-free, timely delivery of your products.
          </div>
          <div>We allow you to add at most 200 packages in one order. </div>
        </div>
      </div>
    </div>
    <div class="cont">
      <div class="title1">Sustainable Delivery Options</div>
      <div class="top-des">
        In response to growing environmental concerns, our tailored delivery solutions have sustainable delivery options.
        Sustainable delivery options focus on minimizing the environmental impact of the delivery process while ensuring
        efficient and reliable services. These options prioritize eco-friendly practices and reduce carbon emissions,
        contributing to a more sustainable and greener approach to logistics. This can involve eco-friendly packaging
        materials, carbon-neutral or low-emission delivery methods, or even the option for customers to consolidate
        multiple orders into a single shipment. By offering sustainable choices, businesses contribute to reducing their
        environmental footprint and appeal to environmentally conscious customers.
      </div>
      <div class="paiban">
        <div class="word" style="margin-right:80px;">
          <img src="../../../assets/images/home/electric.png" alt="">
        </div>
        <div>
          <div class="title">Electric and Low-Emission Vehicles</div>
          <div class="des">
            Our sustainable delivery options often involve the use of electric vehicles (EVs) or low-emission vehicles.
            EVs produce zero tailpipe emissions, reducing air pollution and greenhouse gas emissions. By transitioning to
            electric or low-emission vehicles, ADS can significantly decrease our carbon footprint and contribute to
            cleaner air quality.
          </div>
        </div>
      </div>
      <div class="paiban">
        <div style="margin-right:80px;">
          <div class="title">Packaging Optimization</div>
          <div class="des">
            Sustainable delivery options involve conscious packaging practices. This includes using recyclable,
            biodegradable, or compostable packaging materials to minimize waste generation. Additionally, retailers can
            explore innovative packaging designs that use fewer materials, reducing the overall environmental footprint of
            packaging.
          </div>
        </div>
        <div class="word">
          <img src="../../../assets/images/home/packaging.png" alt="">
        </div>
      </div>
      <div class="paiban">
        <div class="word" style="margin-right:80px;">
          <img src="../../../assets/images/home/collaboration.png" alt="">
        </div>
        <div>
          <div class="title">Collaboration and Partnerships</div>
          <div class="des">
            Sustainable delivery options are fostered through collaborations and partnerships with stakeholders along the
            supply chain. This can involve working with environmentally conscious suppliers, logistics providers, and
            customers to collectively reduce the environmental impact of the entire delivery process.
          </div>
        </div>
      </div>
      <div class="paiban">
        <div style="margin-right:80px;">
          <div class="title">Customer Education and Incentives</div>
          <div class="des">
            Sustainable delivery options also involve educating customers about the importance of environmentally friendly
            practices. ADS can provide information on sustainable packaging choices, encourage recycling, and
            incentivize customers to choose greener delivery options such as consolidated shipments or delivery during
            off-peak hours.
          </div>
        </div>
        <div class="word">
          <img src="../../../assets/images/home/customer.png" alt="">
        </div>
      </div>
      <div class="zongjie">
        By adopting sustainable delivery options, logistics companies can actively contribute to environmental
        conservation and demonstrate their commitment to corporate social responsibility. These practices not only help
        protect the planet but also resonate with environmentally conscious customers, leading to a positive brand image
        and customer loyalty.
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.top-banner {
  box-sizing: border-box;
  position: relative;
  height: 538px;
  overflow: hidden;

  .bg {
    background-image: url('../../../assets/images/home/cangku15.png');
    background-size: cover;
    background-position: 50% 50%;
    height: 540px;
    filter: blur(2px);
  }

  .ban {
    width: 700px;
    position: absolute;
    z-index: 9;
    top: 50%;
    right: 50%;
    transform: translate(50%, -70%);
  }

  .title-big {
    font-weight: 500;
    font-size: 56px;
    color: #fff;
    text-align: center;
    line-height: 64px;
    padding-top: 166px;
  }

  .title-small {
    margin-top: 28px;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    line-height: 28px;
    color: #fff;
  }
}

.cont {
  width: 1300px;
  margin: 0 auto;
  padding: 60px 40px;
  box-sizing: border-box;
  line-height: 1.8;

  .top-des {
    font-size: 24px;
    font-weight: 400;
    color: var(--color-text-2);
    margin-bottom: 60px;
    margin-top: 60px;
  }

  .title {
    font-weight: 300;
    font-size: 36px;
    margin-bottom: 16px;
    color: #333;
  }

  .title1 {
    text-align: center;
    font-weight: 300;
    font-size: 56px;
    line-height: 53px;
    color: #333333;
  }

  .des {
    font-weight: 400;
    font-size: 24px;
    line-height: 1.8;
    color: #7D7D7D;
    padding-top: 10px;
  }

  .paiban {
    display: flex;
    justify-content: space-between;
    margin-top: 100px;

    .word {

      img {
        width: 560px;
        height: 400px;
        border-radius: 8px;
      }
    }
  }

  .zongjie {
    font-weight: 400;
    font-size: 26px;
    line-height: 1.8;
    padding-top: 80px;
  }
}
</style>
